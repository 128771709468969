import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "reactstrap";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType: string) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                //mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
        <>
            <Button color="link" className="nav-link" onClick={() => handleLogout("redirect")}>
                <img src="./images/SignOutIcon.svg" />
                {'  '}Sign out
            </Button>
        </>
    )
    //<Button color="link" className="text-dark nav-link" onClick={() => handleLogout("popup")}>Sign out using Popup</Button>
    //return (
    //    <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Sign Out">
    //        <Dropdown.Item as="button" onClick={() => handleLogout("popup")}>Sign out using Popup</Dropdown.Item>
    //        <Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>Sign out using Redirect</Dropdown.Item>
    //    </DropdownButton>
    //)
}