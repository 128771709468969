import * as React from 'react';
import { useEffect, useState, useImperativeHandle, forwardRef, ChangeEvent } from 'react';
import { fetchCustomer, updateCustomer } from '../../../api/customer';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';

const UpdateProfileForm = forwardRef((props: any, ref: any) => {

    const [email, setEmail] = useState("");
    const [id, setId] = useState(0);
    const [data, setData] = useState({
        id: 0,
        name: "",
        displayName: "",
        email: "",
        phoneNumber: "",
        status: "",
        address: { firstLine: "", secondLine:"", state:"", country: "" },
        industry: "",
        description: "",
        language: "",
        country: "",
        createdAt: "",
        modifiedAt: "",
        profilepic: ""
    });

     //public int CompanyId { get; set; }
     //public string Name { get; set; }
     //   public string DisplayName { get; set; }
     //   public string Description { get; set; }
     //   public string Industry { get; set; }
     //   public string LogoUrl { get; set; }
     //   public string BusinessRegNo { get; set; }
     //   public string Email { get; set; }
     //   public string PhoneNumber { get; set; }
     //   public Address Address { get; set; }
     //   public List < User > Users { get; set; }
     //   public Status Status { get; set; }

    useImperativeHandle(ref, () => ({
        submitForm() {
            updateCustomer(props.id, {
                name: data.name,
                displayName: data.displayName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                status: data.status,
                industry: data.industry,
                description: data.description,
                address: data.address,
                modifiedAt: data.modifiedAt,
            }).then((response) => {
                props.modalCallback();
            });
        }
    }));

    const onLoad = () => {
        if (props.id) {
            setId(props.id);
        }
        fetchCustomer(props.id.toString()).then((result: any) => setData(result)).catch(console.error);
    };

    const setFormChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
        var newData: any = data;
        if (field.includes('address.')) {
            field = field.replace('address.', '');
            var address = newData.address || {};
            address[field] = e.target.value;
            newData.address = address;
        } else {
            newData[field] = e.target.value;
        }
        console.log(newData[field]);
        setData({ ...newData });
    }

    useEffect(() => {
        if (data.id == 0)
            onLoad();
    }, [data, setData]);

    return (
        <Form>
            <Row form>
                <Col md={12}>
                    <h5>Company</h5>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="Name">Name</Label>
                        <Input type="text" name="name" id="name" placeholder={data.name} value={data.name} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormChange(e, 'name')}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="EmailUsername">Email/Username</Label>
                        {/*<Input type="email" name="EmailUsername" id="EmailUsername" placeholder={data.email} onChange={props.onEmailChange()} />*/}
                        <Input type="email" name="EmailUsername" id="EmailUsername" placeholder={data.email} value={data.email} onChange={(e: ChangeEvent<HTMLInputElement>) =>setFormChange(e,'email')} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="Status">Status</Label>
                        <Input type="select" name="Status" id="Status" value={data.status} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormChange(e, 'status')}>
                            <option>Inactive</option>
                            <option>Active</option>
                            <option>Disable</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="DisplayName">Display Name</Label>
                        <Input type="text" name="DisplayName" id="DisplayName" placeholder={data.displayName} value={data.displayName} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormChange(e, 'displayName')}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="PhoneNumber">Phone Number</Label>
                        <Input type="text" name="PhoneNumber" id="PhoneNumber" placeholder={data.phoneNumber} value={data.phoneNumber} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormChange(e, 'phoneNumber')} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="Industry">Industry</Label>
                        <Input type="text" name="Industry" id="Industry" placeholder={data.industry} value={data.industry}  onChange={(e: ChangeEvent<HTMLInputElement>) => setFormChange(e, 'industry')} />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label for="Discription">Discription</Label>
                <Input type="text" name="Discription" id="Discription" placeholder={data.description} value={data.description} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormChange(e, 'description')} />
            </FormGroup>
            <Row form>
                <Col md={12}>
                    <h5>Address</h5>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="Address1">Address 1</Label>
                        <Input type="text" name="Address1" id="Address1" placeholder={
                            data.address != null && data.address.firstLine != null ? data.address.firstLine : "Address First Line"
                        } value={data.address != null && data.address.firstLine != null ? data.address.firstLine : ""} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormChange(e, 'address.firstLine')} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="State">State</Label>
                        <Input type="text" name="State" id="State" placeholder={
                            data.address != null && data.address.state != null? data.address.state : "State not inserted"
                        } value={data.address != null && data.address.state != null ? data.address.state : ""} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormChange(e, 'address.state')} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="Address2">Address 2</Label>
                        <Input type="text" name="Address2" id="Address2" placeholder={
                            data.address != null && data.address.secondLine != null ? data.address.secondLine : "Address Second Line"
                        } value={data.address != null && data.address.secondLine != null ? data.address.secondLine : ""} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormChange(e, 'address.secondLine')} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="Country">Country</Label>
                        <Input type="select" name="Country" id="Country" value={data.country} onChange={(e: ChangeEvent<HTMLInputElement>) => setFormChange(e, 'address.country')}>
                            <option>Malaysia</option>
                            <option>Indonesia</option>
                            <option>Thailand</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
});

export default UpdateProfileForm;