import * as React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import '../../styles/CustomerDetailPage.css';
import '../../styles/PricingPage.css';
import '../../styles/ReportPage.css';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../../authConfig';
import { useEffect, useState } from 'react';
import { PaginationTable } from '../PaginationTable';
import { fetchCustomers } from '../../api/customer';
import { getReports } from '../../api/report';
/*import { saveAs } from 'file-saver';*/
import * as XLSX from 'xlsx';

var FileSaver = require('file-saver');

const getStatusColor = (value: any) => {
    var result = "";

    switch (value.toLowerCase()) {
        case 'unattended':
            result = "./ellipseblue.svg";
            break;
        case 'attended':
            result = "./ellipsegreen.svg";
            break;
        case 'inprogress':
            result = "./ellipseyellow.svg";
            break;
        case 'closed':
            result = "./ellipsered.svg";
            break;
        default:
            result = "./ellipseblue.svg";
    }

    return result;
};

const ReportPage = (props: any) => {
    const authRequest = {
        ...loginRequest
    };
    const { inProgress } = useMsal();
    const [isLoaded, setIsLoaded] = useState(false);

    const [reportSLABreachDataLoaded, setReportSLABreachDataLoaded] = useState(false);
    const [reportTicketDataLoaded, setReportTicketDataLoaded] = useState(false);
    const [reportServiceDataLoaded, setReportServiceDataLoaded] = useState(false);
    const [reportPackageDataLoaded, setReportPackageDataLoaded] = useState(false);

    const [reportSLABreachData, setReportSLABreachData] = useState(new Array<any>());
    const [reportTicketData, setReportTicketData] = useState(new Array<any>());
    const [reportServiceData, setReportServiceData] = useState(new Array<any>());
    const [reportPackageData, setReportPackageData] = useState(new Array<any>());
    const [activeTab, setActiveTab] = React.useState('ReportTicketPage'); //useState('CustomerProfile');
    const companyId = parseInt(props.match.params.id, 10) || 0;
    const toggle = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    // for Export button (configurations)
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (csvData: any, fileName: any) => {

        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);

    }

    // for Export button
    const ExportButton = () => {
        if (activeTab === 'ReportTicketPage')
            exportToCSV(reportTicketData, 'Ticket Report')

        if (activeTab === 'ReportSLABreachPage')
            exportToCSV(reportSLABreachData, 'SLA Breach Report')

        if (activeTab === 'ReportServicePage')
            exportToCSV(reportServiceData, 'Service Report')

        if (activeTab === 'ReportPackagePage')
            exportToCSV(reportPackageData, 'Package Report')
    }

    // for Ticket Page
    const reportTicketColumns = [
        {
            Header: 'Ticket No.',
            accessor: 'ticketNum',
        },
        {
            Header: 'Title',
            accessor: 'title'
        },
        {
            Header: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Package',
            accessor: 'package'
        },
        {
            Header: 'Tier.',
            accessor: 'tier'
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: (row: any) => (
                <>
                    <img src={getStatusColor(row.value)} className="font-icon" />
                    {' '}{row.value}
                </>
            )
        },
        {
            Header: 'Company',
            accessor: 'companyName'
        }
    ];

    // for SLA Breach page
    const reportSLABreachColumns = [
        {
            Header: 'Ticket No.',
            accessor: 'ticketNum'
        },
        {
            Header: 'Scenario',
            accessor: 'scenario'
        },
        {
            Header: 'Tier',
            accessor: 'tier'
        },
        {
            Header: 'Company',
            accessor: 'companyName'
        },
        {
            Header: 'Cost Incurred.',
            accessor: 'costIncured',
            Cell: (row: any) => (
                <>RM {row.value}</>
            )
        },
        {
            Header: 'Cost Company',
            accessor: 'costCompany',
            Cell: (row: any) => (
                <>RM {row.value}</>
            )
        },
        {
            Header: 'Cost Engineer',
            accessor: 'costEngineer',
            Cell: (row: any) => (
                <>RM {row.value}</>
            )
        }
    ];

    

    // for Service page
    const reportServiceColumns = [
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Total',
            accessor: 'total',
            Cell: (row: any) => (
                <>RM {row.value}</>
            )
        },
        {
            Header: 'Company',
            accessor: 'companyName'
        }
    ];

    // for package page 
    const reportPackageColumns = [
        {
            Header: 'Package',
            accessor: 'name'
        },
        {
            Header: 'Scale',
            accessor: 'scale'
        },
        {
            Header: 'Tier',
            accessor: 'tier',
        },
        {
            Header: 'Amount',
            accessor: 'amount',
            Cell: (row: any) => (
                <>RM {row.value}</>
            )
        },
        {
            Header: 'Unit',
            accessor: 'unit'
        },
        {
            Header: 'Monthly/Annually',
            accessor: 'frequency'
        },
        {
            Header: 'Company',
            accessor: 'companyName'
        }
    ];

    const onLoad = () => {
        if (!reportTicketDataLoaded) {
            getReports('ticket', 'SuperAdmin', 0).then((res: any) => {
                setReportTicketData(res);
                setReportTicketDataLoaded(true);
            }).catch(console.error);
        }
        if (!reportSLABreachDataLoaded) {
            getReports('slabreach', 'SuperAdmin', 0).then((res: any) => {
                setReportSLABreachData(res);
                setReportSLABreachDataLoaded(true);
            }).catch(console.error);
        }
        if (!reportServiceDataLoaded) {
            getReports('service', 'SuperAdmin', 0).then((res: any) => {
                setReportServiceData(res);
                setReportServiceDataLoaded(true);
            }).catch(console.error);
          
        }
        if (!reportPackageDataLoaded) {
            getReports('package', 'SuperAdmin', 0).then((res: any) => {
                setReportPackageData(res);
                setReportPackageDataLoaded(true);
            }).catch(console.error);
        }
        if (reportTicketDataLoaded && reportSLABreachDataLoaded && reportServiceDataLoaded && reportPackageDataLoaded)
            setIsLoaded(true);
    };

    useEffect(() => {
        if (!isLoaded && inProgress === InteractionStatus.None)
            onLoad();

    }, [inProgress, reportServiceData, setReportServiceData, reportPackageData, setReportPackageData, reportTicketData, setReportTicketData, reportSLABreachData, setReportSLABreachData, isLoaded, setIsLoaded,
        reportTicketDataLoaded, setReportTicketDataLoaded, reportServiceDataLoaded, setReportServiceDataLoaded, reportPackageDataLoaded, setReportPackageDataLoaded, reportSLABreachDataLoaded, setReportSLABreachDataLoaded ]);

    return (
        <>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
            >
                <div className="PricingPageHeader">
                    <div>
                        <h5 className="card-title">List of Reports</h5>
                    </div>
                    <div className="PricingPageheaderButton">
                        <Button color="secondary" onClick={ExportButton}>
                            <img src="./images/ExportIcon.svg" />{' '}
                            Export
                        </Button>
                    </div>
                </div>
                <div id="company-profile">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'ReportTicketPage' })}
                                onClick={() => { toggle('ReportTicketPage'); }}
                            >
                                Ticket
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'ReportSLABreachPage' })}
                                onClick={() => { toggle('ReportSLABreachPage'); }}
                            >
                                SLA Breach
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'ReportServicePage' })}
                                onClick={() => { toggle('ReportServicePage'); }}
                            >
                                Service
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'ReportPackagePage' })}
                                onClick={() => { toggle('ReportPackagePage'); }}
                            >
                                Package
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="ReportTicketPage">
                            <Row>
                                <Col sm="12">
                                    <h5 className="padding-default card-title">Ticket Report</h5>
                                    <div className="tableContainer reportPageTable">
                                        {
                                            reportTicketDataLoaded ? <PaginationTable columns={reportTicketColumns} data={reportTicketData} /> : <>Loading...</>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="ReportSLABreachPage">
                            <Row>
                                <Col sm="12">
                                    <h5 className="padding-default card-title">SLA Breach Report</h5>
                                    <div className="tableContainer reportPageTable">
                                        {
                                            reportSLABreachDataLoaded ? <PaginationTable columns={reportSLABreachColumns} data={reportSLABreachData} /> : <>Loading...</>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="ReportServicePage">
                            <Row>
                                <Col sm="12">
                                    <h5 className="padding-default card-title">Service Report</h5>
                                    <div className="tableContainer reportPageTable">
                                        {
                                            reportServiceDataLoaded ? <PaginationTable columns={reportServiceColumns} data={reportServiceData} /> : <>Loading...</>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="ReportPackagePage">
                            <Row>
                                <Col sm="12">
                                    <h5 className="padding-default card-title">Package Report</h5>
                                    <div className="tableContainer reportPageTable">
                                        {
                                            reportPackageDataLoaded ? <PaginationTable columns={reportPackageColumns} data={reportPackageData} /> : <>Loading...</>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </MsalAuthenticationTemplate>

        </>
    );
}

export default ReportPage;