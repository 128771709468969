import { AccountInfo, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import * as React from 'react';
import { appRoles, graphConfig, loginRequest } from '../../../authConfig';
import { Container, Row, Col, Card, CardText, ButtonGroup, Button, Input } from 'reactstrap';
/*import { Line } from 'react-chartjs-2';*/
import { Doughnut, Line, Pie } from 'react-chartjs-2';
import classnames from 'classnames';
import '../../../styles/CustomerListPage.css';
import '../../../styles/ticket.css';
import '../../../styles/Dashboard.css';
import { useEffect, useState } from 'react';
import { Chart } from 'chart.js';
import { fetchCustomerByUserId, fetchCustomers } from '../../../api/customer';
import { dashboardTop } from '../../../api/dashboard';
import { callMsGraph } from '../../../graph';
import { DashboardTable } from './DashboardTable';
import Moment from 'react-moment';
import moment from 'moment';
//const moment = require('moment');


interface LineChartProps {
    type?: any;
    labels?: Array<any>;
    datasets?: Array<any>;
    options?: Array<any>;
}


const DashboardPage = (props: any) => {
    const authRequest = {
        ...loginRequest
    };

    var today = Date();

    const [graphData, setGraphData] = useState({ id: "" });
    const { inProgress, instance, accounts } = useMsal();
    const [isAuthorizedSuperAdmin, setIsAuthorizedSuperAdmin] = useState(false);
    const [companyData, setCompanyData] = useState({ id: 0 });

    const requestProfileData = () => {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        if (graphData.id == "") 
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0] as AccountInfo
            }).then((response: any) => {
            
                callMsGraph("GET", response.accessToken, graphConfig.graphMeEndpoint).then(response => {
                    setGraphData(response);
                    fetchCustomerByUserId(response.id).then((data: any) => {
                        setCompanyData(data);
                    
                    }).catch(console.error);
                });
        });
    }


    const checkIfSuperAdmin = () => {
        const currentAccount: any = instance.getActiveAccount();

        if (currentAccount && currentAccount.idTokenClaims) {
            var tokenClaims: any = currentAccount.idTokenClaims['roles'];
            console.log('token');
            console.log(tokenClaims);
            let intersection = tokenClaims.includes(appRoles.SuperAdmin);

            if (intersection) {
                setIsAuthorizedSuperAdmin(true);
            }
        }

    };



    var linechartProps: LineChartProps = { type: "line", labels: ['Nov 20', 'Dec 20', 'Jan 21', 'Feb 21', 'Mar 21', 'Apr 21'], datasets: [{}], options: [{}] };
    const [list, setList] = useState(new Array<any>());
    const [chartData, setChartData] = useState(linechartProps);
    const [statusData, setStatusData] = useState({
        opened: 20,
        attended: 12,
        inProgress: 52,
        closed: 4,
        total: 95
    });


    const [companyChart, setCompanyChart] = useState('1');
    
    const [activeTab, setActiveTab] = React.useState('monthly');
    const [isLoaded, setIsLoaded] = useState(false);
    const [dashboardData, setDashboardData] = useState({
        opened: 0,
        attended: 0,
        inProgress: 0,
        closed: 0,
        total: 0,
        chartLabels: new Array<any>(),
        chartValues: new Array<any>(),
    });

    const toggle = (tab: any, category: any) => {

        dashboardTop(companyData.id, tab, category).then((response: any) => {
            setDashboardData(response);

            setActiveTab(tab)
        })
    }

    const getTicketColor = (label: string) => {
        let color = "black";
        switch (label) {
            case "Unattended":
                color = "blue";
                break;
            case "Attended":
                color = "green";
                break;
            case "InProgress":
                color = "yellow";
                break;
            case "Closed":
                color = "pink";
                break;
        }
        return color;
    }

    // get number of weeks
    var currentDate = moment(Date()).format('D');
    var numOfWeek = Math.ceil((parseInt(currentDate) - 1) / 7);
    var emptyArray = [];

    for (let i = 1; i <= numOfWeek; i++) {
        emptyArray.push({ id: i, value: 'Week ' + (i) });
    }

    const [weekArray, setWeekArray] = useState([emptyArray]);


    const linechart = () => {
        setChartData({
            type: 'line',
            labels: dashboardData.chartLabels,
            datasets: dashboardData.chartValues.map((item: any) => {
                return {
                    label: item.name,
                    data: item.values,
                    backgroundColor: [
                        getTicketColor(item.name)
                    ],
                    borderWidth: 5
                };
            })
        });
    };

   

    const onLoad = () => {

        
        requestProfileData();
        checkIfSuperAdmin();
        /*getCompanyId();*/

        //fetchCustomers().then((data: any) => {
        //    setList(data.list);
        //}).catch(console.error);

        /*console.log('Company Id', companyData.id);*/

        if (companyData != null && companyData.id != null && companyData.id > 0 && !isLoaded)
            dashboardTop(companyData.id, activeTab, "").then((response: any) => {
                setDashboardData(response);
                setIsLoaded(true);
            
        })
    };


    useEffect(() => {
        /*if (list.length === 0)*/
            onLoad();
            linechart();


        
    }, [graphData, setGraphData, list, setList, dashboardData, setDashboardData,isLoaded, setIsLoaded, isAuthorizedSuperAdmin, setIsAuthorizedSuperAdmin,companyData, setCompanyData]);

    return (
        <>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
            >
                <div className="Top-Section">
                    <div className="card-header">
                        <div className="header-left">
                            <h6 className="card-title">Ticket Dashboard</h6>
                        </div>
                        <div className="header-right">
                            <div className="select-frequency">
                                
                                    <Button color="" id="Monthly-button"
                                        className={classnames({ active: activeTab === 'monthly' })}
                                        onClick={() => { toggle('monthly', ''); }}
                                    >
                                        Monthly
                                    </Button>
                                    <Button color=""
                                        className={classnames({ active: activeTab === 'weekly' })}
                                        onClick={() => { toggle('weekly', ''); }}
                                    >
                                        Weekly
                                    </Button>
                                    <Button color=""
                                        className={classnames({ active: activeTab === 'today' })}
                                        onClick={() => { toggle('today', ''); }}
                                    >
                                        Today
                                    </Button>
                                
                            </div>
                        </div>
                    </div>
                    <div className="Dashboard-container">
                        <Row>
                            <Col lg="6">
                                <Row id="Card-row">
                                    <Col lg="6">
                                        <Card className="Status-card">
                                            <CardText id="Status-opened" className="Status">{dashboardData.opened}</CardText>
                                            <CardText>Unattended</CardText>
                                        </Card>
                                    </Col>
                                    <Col lg="6">
                                        <Card className="Status-card">
                                            <CardText id="Status-attended" className="Status">{dashboardData.attended}</CardText>
                                            <CardText>Attended</CardText>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <Card className="Status-card">
                                            <CardText id="Status-inprogress" className="Status">{dashboardData.inProgress}</CardText>
                                            <CardText>In-Progress</CardText>
                                        </Card>
                                    </Col>
                                    <Col lg="6">
                                        <Card className="Status-card">
                                            <CardText id="Status-closed" className="Status">{dashboardData.closed}</CardText>
                                            <CardText>Closed</CardText>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="6">
                                <div className="Graph-Container">
                                    <Row>
                                        <Col lg="2">
                                            <Card className="TotalTicket-card">
                                                <CardText id="Total-Ticket" className="Total-Ticket">{dashboardData.total}</CardText>
                                                <CardText>Total Ticket</CardText>
                                            </Card>
                                        </Col>
                                        <Col lg="5"></Col>
                                        <Col lg="5">
                                            <div>
                                                {activeTab == 'weekly' ? <Input type="select" name="select-week" id="select-week" onChange={(e: any) => { toggle('weekly', e.target.value); }}>
                                                    {weekArray[0].map((option: any) => (
                                                        <option value={option.id}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </Input> : ""}

                                                {activeTab == 'today' ? <h6>
                                                    <Moment format="DD MMM YYYY">
                                                        {today}
                                                    </Moment>
                                                </h6> : ""}

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Line
                                                type="line"
                                                data={chartData}
                                                options={{
                                                    scales: {
                                                        yAxis: {
                                                            display: false
                                                        }
                                                    },
                                                    plugins: {
                                                        legend: {
                                                            display: false
                                                        }
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </MsalAuthenticationTemplate>
        </>
    );
};
export default DashboardPage;


