import * as React from 'react';
import Tippy from '@tippyjs/react';
import { PaginationTable } from "../PaginationTable";
import { fetchCustomers } from "../../api/customer";
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
import '../../styles/ticket.css';
import 'tippy.js/dist/tippy.css';
import { fetchSLAStatus, fetchTicket, replyTicketComment, updateResolutionTime, updateTicketStatus } from '../../api/ticket';
import Moment from 'react-moment';
import { useMsal } from "@azure/msal-react";
import { graphConfig, loginRequest } from "../../authConfig";
import { callMsGraph } from "../../graph";
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { AccountInfo, AuthenticationResult, AuthenticationScheme, EventMessage, InteractionStatus, InteractionType } from '@azure/msal-browser';

function Document(props: any) {
    const downloadAll = (e: any, files: any) => {
        e.preventDefault();
        files.map((item: any) => {
            window.open(item.path);
        });
    };
    return (
        <Row className="row-component">
            <Col lg="1">
            </Col>
            <Col lg="11" id="file-upload-col">
                <div className="file-upload">
                    <div id="upload-content">
                        {
                            props && props.attachments ? props.attachments.map((item: any) => (
                                <div className="content">
                                    <a href={item.path}><h6><img src="./insertphoto.svg" /> {item.name}</h6></a>
                                </div>)) : null
                        }
                    </div>
                    <div id="uploadDocument">
                        {
                            props.attachments.length > 0 ? <div className="file-counter">
                                <h6><strong>{props.attachments.length} files attached</strong></h6>
                                <div className="download-button">
                                    <button style={{ border: 'none' }} onClick={(e) => downloadAll(e, props.attachments)}><h6><img src="./download.svg" /><strong> Download All</strong></h6></button>
                                </div>
                            </div> : null
                        }
                    </div>
                </div>
            </Col>
        </Row>
    );
}

function Message(props: any) {
    const getReplyType = (type: any) => {
        if (type == "Call")
            return "./callgrey.svg";
        else {
            return "./chat.svg";
        }
    };

    return (
        <Row className="row-component">
            <Col lg="12">
                <Row>
                    <Col lg="8" id="message-header">
                        <div>
                            <img className="customer-pic" src={props.data && props.data.creatorProfilePic ? props.data.creatorProfilePic : './images/customer-icon.png'} />
                        </div>
                        <div id="message-sender">
                            <span>
                                <h6><strong>{props.data.creatorName}</strong> <img src={getReplyType(props.data.replyType)} /></h6>
                            </span>
                        </div>
                    </Col>
                    <Col lg="4" id="date-col">
                        <div id="message-date">
                            <span>
                                <h6>
                                    <Moment format="DD MMM YYYY">
                                        {props.data.createdAt}
                                    </Moment>
                                </h6>
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="1">
                    </Col>
                    <Col lg="11">
                        <div dangerouslySetInnerHTML={{ __html: props.data.content }}>
                            {/*{props.data.content}*/}
                        </div>
                    </Col>
                </Row>
                <Document attachments={props.data.attachments} />
                <Row>
                    <Col lg="1">
                    </Col>
                    <Col lg="11">
                        <div id="reply-status">
                            <span>
                                <h6>{props.data.replyType == 'Call' ? 'Call made' : 'Replied' } by {props.data.creatorName} at <Moment format="h:mm a">{props.data.createdAt}</Moment>
                                </h6>
                            </span>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

function HoursForm(props: any) {

    return (
        <Form>
            <Row form>
                <Col md={12}>
                    <FormGroup>
                        <Label for="Hours">Hours</Label>
                        <Input value={props.inputValue} onChange={(e: any) => props.onChangeCallback(e, "Hours")} type="number" name="Hours" id="Hours" max="23" placeholder="Insert hours">
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
}

function DaysForm(props: any) {

    return (
        <Form>
            <Row form>
                <Col md={12}>
                    <FormGroup>
                        <Label for="Days">Days</Label>
                        <Input value={props.inputValue} onChange={(e: any) => props.onChangeCallback(e, "Days")} type="number" name="Days" id="Days" placeholder="Insert Days">
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
}


const TicketPageExternal = (props: any) => {
    const authRequest = {
        ...loginRequest
    };
    const ticketId = parseInt(props.match.params.id, 10) || 0;
    const [id, setId] = useState(0);
    const [chatField, setChatField] = useState(false);
    const [data, setData] = useState({
        ticketId: 0,
        ticketNum: "",
        tier: "basic",
        title: "",
        description: "",
        name: "",
        creatorName: "",
        displayName: "",
        email: "",
        phoneNumber: "",
        status: "",
        address: "",
        companyAddress: "",
        industry: "",
        language: "",
        country: "",
        createdAt: "",
        modifiedAt: "",
        profilepic: "",
        resolutionTime: 0,
        creatorEmail: "",
        creatorPhoneNumber: "",
        creatorStatus: "",
        creatorProfilePic: "",
        package: "",
        comments: new Array<any>()
    });

    const { inProgress, instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState({ id: "" });
    const [ticketStatus, setTicketStatus] = useState('');
    const [replyData, setReplyData] = useState('');
    const [inputResolutionTime, setInputResolutionTime] = useState(data.resolutionTime);
    const [SLABreachType, setSLABreachType] = useState('');

    const [attachments, setAttachments] = useState(new Array<any>());
    const fileInput: any = useRef(null);

    const ReplyDocument = (props: any) => {

        // delete file button
        const files = attachments

        const deleteFile = (id: any) => {

            for (var i = 0; i < files.length; i++) {

                if (files[i].id === id) {
                    files.splice(i, 1)
                    setAttachments([...files])
                }
            }
        }

        return (
            <Row className="row-component">
                <Col lg="12" id="file-upload-col">
                    <div className="file-upload">
                        <div id="upload-content">
                            {
                                attachments ? attachments.map((item: any) => (
                                    <div className="content">
                                        <h6><img src="./insertphoto.svg" /> {item.name}</h6>
                                        <a className="padding-default" onClick={() => deleteFile(item.id)}><img src="./delete.svg" className="font-icon" /></a>
                                    </div>)) : null
                            }
                        </div>
                        <div id="uploadDocument">
                            {
                                attachments.length > 0 ? <div className="file-counter">
                                    <h6><strong>{attachments.length} files attached</strong></h6>
                                </div> : null
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    const saveFile = (e: any) => {
        for (var i = 0; i < e.target.files.length; i++) {
            console.log(e.target.files[i]);

            var _attachments = attachments;
            _attachments.push({
                file: e.target.files[i],
                name: e.target.files[i].name,
                path: '#'
            });
            setAttachments([..._attachments]);
        }
    };

    const handleClick = () => {
        fileInput.current.click();
    };


    const updateResoTime = (evt: any, type: string) => {
        var val = evt.target.value;
        setInputResolutionTime(val);

        updateResolutionTime(data.ticketId, type, val)
            .then((res) => {
                var newData = data;
                if (type == "Days") {
                    newData.resolutionTime = val * 24;
                } else {
                    newData.resolutionTime = val;
                }
                setData({ ...newData });
            }).catch(console.error);
    };
    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0] as AccountInfo
        }).then((response: AuthenticationResult) => {
            callMsGraph("GET",response.accessToken, graphConfig.graphMeEndpoint).then(response => {
                console.log(response);
                setGraphData(response);
            });
        });
    }

    const onLoad = () => {
        if (ticketId) {
            setId(ticketId);
        }
        fetchTicket(ticketId).then((result: any) => {
            setData(result);
            setTicketStatus(result.status.toLowerCase());
        }).catch(console.error);
        fetchSLAStatus(ticketId).then((result: any) => {
            setSLABreachType(result);
        }).catch(console.error);
    };

    function getData(val: any) {
        setChatField(true);
        setReplyData(val.target.value);   
    };

    const replyCommentCall = (e: MouseEvent) => {
        e.preventDefault();
        if (replyData == null || replyData.length === 0) {
            return alert('content is empty');
        }
        var userId = "";
        if (graphData != null && graphData.id != null) {
            userId = graphData.id;
        }

        var formData = new FormData();
        formData.append("Content", replyData.replace(/\n/g, "<br>"));
        formData.append("UserId", userId);
        formData.append("ReplyType", selectedMessageType);
        var headerObj = {
            'content-type': `multipart/form-data;`,
        };

        if (attachments != null) {
            attachments.map((item: any) => {
                formData.append("Attachments", item.file);
            })
            headerObj['content-type'] = headerObj['content-type'] + ' boundary=${formData._boundary}';
        }

        replyTicketComment(ticketId, formData, headerObj).then((result: any) => {
            setReplyData('');
            setAttachments([...new Array<any>()]);
            //lps dh post comment, update ui dgn comment terbaru
            var newData = data;
            console.log(newData);
            newData.comments.push(result);
            //setData(...newData);  //setData({ ...newData }) setData(newData)
            setData({ ...newData });
            //fetchTicket(ticketId).then((result: any) => setData(result)).catch(console.error);
        }).catch((e: any) => {
            alert(e);
        })
    };

    useEffect(() => {
        if (data.ticketId == 0 && inProgress === InteractionStatus.None) {
            onLoad();
            RequestProfileData();
        }
    }, [inProgress, data, setData, ticketStatus, setTicketStatus, instance]);

    const handleUpdateTicketStatus = (event: any) => {
        setTicketStatus(event.target.value);
        updateTicketStatus(data.ticketId, event.target.value, graphData.id).then((data) => { //action after reply here

        }).catch(console.error);
    };

    const ticketStatusOptions = [
        { key: 'unattended', value: 'Unattended' },
        { key: 'attended', value: 'Attended' },
        { key: 'inprogress', value: 'In-Progress' },
        { key: 'closed', value: 'Mark as Close' }
    ];

    const ticketSLABreachs = [
        //{ key: 'none', value: '~/slabreach/slabreach_none.png' },
        { key: 'timetoattend', label: 'SLA Breached: Time to Attend', value: './slabreach/slabreach_timetoattend.png' },
        { key: 'timetoresolution', label: 'SLA Breached: Time to Set Resolution Time', value: './slabreach/slabreach_timetoresolution.png' },
        { key: 'timetocompletion', label: 'SLA Breached: Time to Complete', value: './slabreach/slabreach_timetocompletion.png' },
        { key: 'timetoinittialcall', label: 'SLA Breached: Time to Initiate Call', value: './slabreach/slabreach_timetoinittialcall.png' }
    ];

    //this is for dropdown and modals component for resolution time
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        //if (inputResolutionTime == 0 && (data.resolutionTime == null || data.resolutionTime == 0)) {
        //    setDropdownOpen(prevState => !prevState);
        //}
    };

    const [modalHours, setModalHours] = useState(false);
    const [modalDays, setModalDays] = useState(false);
    const [selectedMessageType, setSelectedMessageType] = useState("Message");
    const toggleHours = () => setModalHours(!modalHours);
    const toggleDays = () => setModalDays(!modalDays);

    //this is for Customer Info in mobile screen
    const [mobileModal, setMobileModal] = useState(false);
    const toggleMobileModal = () => setMobileModal(!mobileModal);

    return (
        <>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
            >
                <div className="card-header">
                    <div>
                        <h5 className="card-title">Ticket</h5>
                    </div>
                </div>
                <Container fluid={true}>
                    <Row className="main-row">
                        <Col lg="8" className="main-col">
                            <Row className="row-component">
                                <Col lg="6">
                                    <div>
                                        <Row id="ticket-status">
                                            <Col lg="12" className="ticketStatus-header">
                                                <h5><strong>{data.title}</strong></h5>
                                                {/*Below this is Customer Info modal for mobile screen*/}
                                                <div className="d-block d-md-none d-lg-none">
                                                    <Button color="danger" onClick={toggleMobileModal} style={{ background: 'none', border: 'none' }}> <img src="./images/TripleDots.svg" /></Button>
                                                    <Modal isOpen={mobileModal} toggle={toggleMobileModal} centered={true} backdrop={false}>
                                                        <ModalHeader toggle={toggleMobileModal}></ModalHeader>
                                                        <ModalBody>
                                                            <Col lg="12">
                                                                <Row className="side-row">
                                                                    <Col lg="12" className="Customer-info">
                                                                        <Row className="side-row">
                                                                            <Col lg="12">
                                                                                <div className="Customer-logo">
                                                                                    <img className="customer-pic" src={data && data.creatorProfilePic ? data.creatorProfilePic : './images/customer-icon.png'} />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="side-row">
                                                                            <Col lg="12">
                                                                                <div className="Customer-name">
                                                                                    <h5><strong>{data && data.creatorName ? data.creatorName : ''}</strong></h5>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="side-row">
                                                                            <Col lg="12">
                                                                                <div className="Customer-status">
                                                                                    <h6>Customer</h6>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <div className="Info-title">
                                                                            <span>
                                                                                <h6><strong>User Information</strong></h6>
                                                                            </span>
                                                                        </div>
                                                                        {
                                                                            data && data.creatorEmail ? <div id="Customer-email">
                                                                                <span>
                                                                                    <h6><img src="./mail.svg" />{data.creatorEmail}</h6>
                                                                                </span>
                                                                            </div> : null
                                                                        }
                                                                        {
                                                                            data && data.creatorPhoneNumber ? <div id="Customer-contact">
                                                                                <span>
                                                                                    <h6><img src="./callgrey.svg" />{data.creatorPhoneNumber}</h6>
                                                                                </span>
                                                                            </div> : null
                                                                        }
                                                                        <div>
                                                                            <Row>
                                                                                <Col lg="6">
                                                                                    <div>
                                                                                        <span>
                                                                                            <h6><strong>Ticket No:</strong>{data.ticketNum}</h6>
                                                                                        </span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <div>
                                                                                        <span>
                                                                                            <h6><strong>Requested:</strong>{data.creatorName}</h6>
                                                                                        </span>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <div>
                                                                            <Row>
                                                                                <Col lg="6">
                                                                                    <div id="Cust-Status">
                                                                                        <span>
                                                                                            <h6><strong>Status:</strong>{data.creatorStatus}</h6>
                                                                                        </span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg="6">

                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </ModalBody>
                                                    </Modal>
                                                </div>
                                            </Col>
                                            <Col lg="12">
                                                <h6>{data.ticketNum}</h6>
                                            </Col>
                                            <Col lg="12">
                                                <h6>{data.package}</h6>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg="2">
                                    {/*{*/}
                                    {/*    SLABreachType && SLABreachType.length > 0 ?*/}
                                    {/*        ticketSLABreachs.map((item: any) => {*/}
                                    {/*            if (item.key == SLABreachType) {*/}
                                    {/*                return (<div>*/}
                                    {/*                    <Tippy content={item.label}>*/}
                                    {/*                        <img className="icon-slabreach" src={item.value} />*/}
                                    {/*                    </Tippy>*/}
                                    {/*                </div>);*/}
                                    {/*            }*/}
                                    {/*        }) : null*/}
                                    {/*}*/}
                                </Col>
                                <Col lg="4" className="Resolution-time">
                                    {/*<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>*/}
                                    {/*    <DropdownToggle color="" id="Reso-Dropdown">*/}
                                    {/*        Resolution Time: {data.resolutionTime < 24 ? data.resolutionTime : data.resolutionTime / 24} {data.resolutionTime < 24 ? "hours" : "days"}*/}
                                    {/*    </DropdownToggle>*/}
                                    {/*    <DropdownMenu>*/}
                                    {/*        <DropdownItem header>Choose Time Option</DropdownItem>*/}
                                    {/*        <DropdownItem>*/}
                                    {/*            <Button color="danger" onClick={toggleHours}>Hours</Button>*/}
                                    {/*            <Modal isOpen={modalHours} toggle={toggleHours} className="Hours">*/}
                                    {/*                <ModalHeader toggle={toggleHours}>Resolution Time</ModalHeader>*/}
                                    {/*                <ModalBody>*/}
                                    {/*                    <HoursForm inputValue={inputResolutionTime} onChangeCallback={updateResoTime} />*/}
                                    {/*                </ModalBody>*/}
                                    {/*                <ModalFooter>*/}
                                    {/*                    <Button color="primary" onClick={toggleHours}>Cancel</Button>{' '}*/}
                                    {/*                    <Button color="secondary" onClick={toggleHours}>Confirm</Button>*/}
                                    {/*                </ModalFooter>*/}
                                    {/*            </Modal>*/}
                                    {/*        </DropdownItem>*/}
                                    {/*        <DropdownItem>*/}
                                    {/*            <Button color="danger" onClick={toggleDays}>Days</Button>*/}
                                    {/*            <Modal isOpen={modalDays} toggle={toggleDays} className="Days">*/}
                                    {/*                <ModalHeader toggle={toggleDays}>Resolution Time</ModalHeader>*/}
                                    {/*                <ModalBody>*/}
                                    {/*                    <DaysForm inputValue={inputResolutionTime} onChangeCallback={updateResoTime} />*/}
                                    {/*                </ModalBody>*/}
                                    {/*                <ModalFooter>*/}
                                    {/*                    <Button color="primary" onClick={toggleDays}>Cancel</Button>{' '}*/}
                                    {/*                    <Button color="secondary" onClick={toggleDays}>Confirm</Button>*/}
                                    {/*                </ModalFooter>*/}
                                    {/*            </Modal>*/}
                                    {/*        </DropdownItem>*/}
                                    {/*    </DropdownMenu>*/}
                                    {/*</Dropdown>*/}
                                </Col>
                            </Row>
                            <Row id="header-end">
                                <Col lg="7"></Col>
                                <Col lg="5">
                                    <div id="status">
                                        <select disabled={true} name="status" id="status" value={ticketStatus} onChange={handleUpdateTicketStatus}>
                                            {ticketStatusOptions.map((option) => (
                                                <option key={option.key} value={option.key}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            {
                                data && data.comments ? data.comments.map((item) => (<Message data={item} />)) : null
                            }
                            <Row className="row-component">
                                <Col lg="12">
                                    <div className="row-component">
                                        <Row>
                                            <Col lg="12" className="Chatbox">
                                                <Row className="Chatbox-header">
                                                    <Col lg="3" id="chat-title">
                                                        <Button type="button" color="link" className={`btn-reset ${selectedMessageType == 'Message' ? 'btn-reset--active ' : ''}`} onClick={(e: any) => setSelectedMessageType("Message")}>
                                                            <h6><strong>Reply via Message</strong></h6>
                                                        </Button>
                                                    </Col>
                                                    {
                                                        data.tier.toLowerCase() == 'premium' ?
                                                            <Col lg="3" id="chat-title">
                                                                <Button type="button" color="link" className={`btn-reset ${selectedMessageType == 'Call' ? 'btn-reset--active ' : ''}`} onClick={(e: any) => setSelectedMessageType("Call")}>
                                                                    <h6><strong>Reply via Call</strong></h6>
                                                                </Button>
                                                            </Col> : null
                                                    }
                                                </Row>
                                                <div className="Chatbox-body">
                                                    <FormGroup>
                                                        <Input disabled={ticketStatus == 'closed'} type="textarea" name="text" id="chatarea" value={replyData} onChange={getData} />
                                                        {/*<Input type="textarea" name="text" id="chatarea" onChange={(e) => {setValue(e.target.value)} />*/}
                                                    </FormGroup>
                                                </div>
                                                <ReplyDocument attachments={attachments} />
                                                <div className="Chatbox-footer">
                                                    <div className="footer-component">
                                                        <Button color="primary" id="reply-button" onClick={replyCommentCall}>Reply</Button>
                                                    </div>
                                                    <div className="footer-component">
                                                        <input
                                                            name="Attachments" id="Attachments"
                                                            ref={fileInput}
                                                            onChange={saveFile}
                                                            type="file"
                                                            style={{ display: "none" }}
                                                            multiple={true}
                                                        />
                                                        <button style={{ border: 'none' }} type="button" className="btn btn-link" onClick={handleClick}><img src="./attachment.svg" /></button>
                                                    </div>
                                                    <div className="footer-component">
                                                        <a><img src="./emoticon.svg" /></a>
                                                    </div>
                                                    <div className="footer-component">
                                                        <a><img src="./photoupload.svg" /></a>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="4" className="d-none d-md-block d-lg-block">
                            <Row className="side-row">
                                <Col lg="12" className="Customer-info">
                                    <Row className="side-row">
                                        <Col lg="12">
                                            <div className="Customer-logo">
                                                <img className="customer-pic" src={data && data.creatorProfilePic ? data.creatorProfilePic : './images/customer-icon.png'} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="side-row">
                                        <Col lg="12">
                                            <div className="Customer-name">
                                                <h5><strong>{data && data.creatorName ? data.creatorName : '' }</strong></h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="side-row">
                                        <Col lg="12">
                                            <div className="Customer-status">
                                                <h6>Customer</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <div className="Info-title">
                                        <span>
                                            <h6><strong>User Information</strong></h6>
                                        </span>
                                    </div>
                                    {
                                        data && data.creatorEmail ? <div id="Customer-email">
                                            <span>
                                                <h6><img src="./mail.svg" />{data.creatorEmail}</h6>
                                            </span>
                                        </div> : null
                                    }
                                    {
                                        data && data.creatorPhoneNumber ? <div id="Customer-contact">
                                            <span>
                                                <h6><img src="./callgrey.svg" />{data.creatorPhoneNumber}</h6>
                                            </span>
                                        </div> : null
                                    }
                                    {/*<div id="Customer-location">*/}
                                    {/*    <div id="location-icon">*/}
                                    {/*        <span>*/}
                                    {/*            <img src="./locationgrey.svg" />*/}
                                    {/*        </span>*/}
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        <span>*/}
                                    {/*            <h6>{data.companyAddress}</h6>*/}
                                    {/*        </span>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div>
                                        <Row>
                                            <Col lg="6">
                                                <div>
                                                    <span>
                                                        <h6><strong>Ticket No:</strong>{data.ticketNum}</h6>
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div>
                                                    <span>
                                                        <h6><strong>Requested:</strong>{data.creatorName}</h6>
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <Row>
                                            <Col lg="6">
                                                <div id="Cust-Status">
                                                    <span>
                                                        <h6><strong>Status:</strong>{data.creatorStatus}</h6>
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col lg="6">

                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </MsalAuthenticationTemplate>
        </>
    );
};

export default TicketPageExternal;