import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import * as React from 'react';
import { loginRequest } from '../../../authConfig';
import { Container, Row, Col, Card, CardText, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Input } from 'reactstrap';
/*import { Line } from 'react-chartjs-2';*/
import { Doughnut, Line, Pie } from 'react-chartjs-2';
import classnames from 'classnames';
import Select from 'react-select';
import '../../../styles/CustomerListPage.css';
import '../../../styles/ticket.css';
import '../../../styles/Dashboard.css';
import { ChangeEvent, useEffect, useState } from 'react';
import { fetchCustomers } from '../../../api/customer';
import { dashboardTop } from '../../../api/dashboard';
import { handleInputChange } from 'react-select/src/utils';
import Moment from 'react-moment';
import moment from 'moment';
/*import { Chart } from 'chart.js';*/


interface LineChartProps {
    type?: any;
    labels?: Array<any>;
    datasets?: Array<any>;
    options?: Array<any>;
}

interface WeekProps {
    id: any;
    value: any;
}
const HomeTopSection = (props: any) => {
    const authRequest = {
        ...loginRequest
    };

    // Line Chart Part
    var today = Date();
    
    var linechartProps: LineChartProps = { type: "line", labels: ['Nov 20', 'Dec 20', 'Jan 21', 'Feb 21', 'Mar 21', 'Apr 21'], datasets: [{}], options: [{}] };
    const [list, setList] = useState(new Array<any>());
    const [id, setId] = useState(0);
    const [data, setData] = useState({
        id: 0,
        name: "",
        displayName: "",
    });

    const [isLoaded, setIsLoaded] = useState(false);
    const [chartData, setChartData] = useState(linechartProps);
    const [selectedWeek, setSelectedWeek] = useState('');
    const [companyChart, setCompanyChart] = useState('0');
    const [activeTopTab, setActiveTopTab] = React.useState('monthly');
    const [dashboardData, setDashboardData] = useState({
        opened: 0,
        attended: 0,
        inProgress: 0,
        closed: 0,
        total: 0,
        chartLabels: new Array<any>(),
        chartValues: new Array<any>(),
    });

    const toggleTop = (tab: any, category: any) => {
        //alert(JSON.stringify(weekArray));
        let newCategory = category;
        if (tab == 'weekly' && (category == null || category == '') && selectedWeek.length > 0) {
            newCategory = selectedWeek;
        }

        dashboardTop(parseInt(companyChart), tab, newCategory).then((response: any) => {
            setDashboardData(response);
            if (tab == 'weekly') {
                setSelectedWeek(newCategory);
            }
            setActiveTopTab(tab)
        })
    }

    const getTicketColor = (label: string) => {
        let color = "black";
        switch (label) {
            case "Unattended":
                color = "blue";
                break;
            case "Attended":
                color = "green";
                break;
            case "InProgress":
                color = "yellow";
                break;
            case "Closed":
                color = "pink";
                break;
        }
        return color;
    }

    // get number of weeks
    var currentDate = moment(Date()).format('D');
    var numOfWeek = Math.ceil((parseInt(currentDate) - 1) / 7);

    /*var latestWeek = emptyArray[emptyArray.length-1];*/

    const [weekArray, setWeekArray] = useState(new Array<WeekProps>());
    /*const [weekLatest, setWeekLatest] = useState([...latestWeek]);*/

    /*var latestWeek = emptyArray.pop();*/

    /*console.log('latest week', weekLatest[0].id);*/
    //console.log('week array', weekArray);

    const linechart = () => {
        setChartData({
            type: 'line',
            labels: dashboardData.chartLabels,
            datasets: dashboardData.chartValues.map((item: any) => {
                return {
                    label: item.name,
                    data: item.values,
                    backgroundColor: [
                        getTicketColor(item.name)
                    ],
                    borderWidth: 5
                };
            })
        });
    };

    const setInputChange = (e: ChangeEvent<HTMLInputElement>, field: any) => {

        dashboardTop(parseInt(e.target.value), activeTopTab, "").then((response: any) => {
            setDashboardData(response);
            setCompanyChart(e.target.value);
            console.log(companyChart);
        })
    };

    const populateWeek = () => {
        let emptyArray: Array<WeekProps> = new Array<WeekProps>();
        for (let i = 1; i <= numOfWeek; i++) {
            emptyArray.push({ id: i, value: 'Week ' + (i) });
        }
        setWeekArray([...emptyArray]);
        if (emptyArray.length > 0) {
            let lastArrayItem = emptyArray.pop();//weekArray[weekArray.length - 1].id;

            if (lastArrayItem) {
                let newCategory = lastArrayItem.id.toString();
                setSelectedWeek(newCategory);
            }
        }
    }

    const onLoad = () => {
        if (selectedWeek.length == 0) {
            populateWeek();
        }

        if (data.id == 0 && !isLoaded) {
            fetchCustomers().then((data: any) => {
                setList(data.list);
            }).catch(console.error);
        }

        if (data.id == 0 && !isLoaded)
            dashboardTop(parseInt(companyChart), activeTopTab, "").then((response: any) => {
                setDashboardData(response);
                setIsLoaded(true);
                /*console.log(dashboardData.chartAttendedValues);*/
            })
    };

    useEffect(() => {
        if (list.length === 0 && !isLoaded && activeTopTab == 'monthly')
            onLoad();
            linechart();
        
    }, [list, setList, dashboardData, setDashboardData, isLoaded, setIsLoaded, isLoaded, setIsLoaded, data, setData, weekArray, setWeekArray, selectedWeek, setSelectedWeek]);

   

    return (
        <>

            <div className="Top-Section">
                <div className="card-header">
                    <div className="header-left">
                        <h6 className="card-title">Ticket Dashboard</h6>
                    </div>
                    <div className="header-right">
                        <div className="select-company">
                            <Input type="select" name="select-company" id="select-company" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputChange(e, 'company')}>
                                <option value='0' >All Company</option>
                                {list.map((option: any) => (
                                    <option value={option.id}>
                                        {option.displayName}
                                    </option>
                                ))}
                            </Input>
                        </div>
                        <div className="select-frequency">

                            <Button color="" id="Monthly-button"
                                className={classnames({ active: activeTopTab === 'monthly' })}
                                onClick={() => { toggleTop('monthly', ''); }}
                            >
                                Monthly
                                </Button>
                            <Button color=""
                                className={classnames({ active: activeTopTab === 'weekly' })}
                                onClick={() => { toggleTop('weekly', ''); }}
                            >
                                Weekly
                                </Button>
                            <Button color=""
                                className={classnames({ active: activeTopTab === 'today' })}
                                onClick={() => { toggleTop('today', ''); }}
                            >
                                Today
                                </Button>

                        </div>
                    </div>
                </div>
                <div className="Dashboard-container">
                    <Row>
                        <Col lg="6">
                            <Row id="Card-row">
                                <Col lg="6">
                                    <Card className="Status-card">
                                        <CardText id="Status-opened" className="Status">{dashboardData.opened}</CardText>
                                        <CardText>Unattended</CardText>
                                    </Card>
                                </Col>
                                <Col lg="6">
                                    <Card className="Status-card Status-attended">
                                        <CardText id="Status-attended" className="Status">{dashboardData.attended}</CardText>
                                        <CardText>Attended</CardText>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <Card className="Status-card">
                                        <CardText id="Status-inprogress" className="Status">{dashboardData.inProgress}</CardText>
                                        <CardText>In-Progress</CardText>
                                    </Card>
                                </Col>
                                <Col lg="6">
                                    <Card className="Status-card">
                                        <CardText id="Status-closed" className="Status">{dashboardData.closed}</CardText>
                                        <CardText>Closed</CardText>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="6">
                            <div className="Graph-Container">
                                <Row>
                                    <Col lg="12">
                                        <Row>
                                            <Col lg="2">
                                                <Card className="TotalTicket-card">
                                                    <CardText id="Total-Ticket" className="Total-Ticket">{dashboardData.total}</CardText>
                                                    <CardText>Total Ticket</CardText>
                                                </Card>
                                            </Col>
                                            <Col lg="5"></Col>
                                            <Col lg="5">
                                                <div>
                                                    {activeTopTab == 'weekly' ? <Input type="select" name="select-week" value={selectedWeek} onChange={(e: any) => { toggleTop('weekly', e.target.value); }}>
                                                        {weekArray.map((option: any) => (
                                                            <option value={option.id}>
                                                                {option.value}
                                                            </option>
                                                        ))}
                                                        {/*{weekLatest.map((option: any) => (*/}
                                                        {/*    <option value={option.id} selected>*/}
                                                        {/*        {option.value}*/}
                                                        {/*    </option>*/}
                                                        {/*))}*/}
                                                    </Input> : ""}

                                                    {activeTopTab == 'today' ? <h6>
                                                        <Moment format="DD MMM YYYY">
                                                            {today}
                                                        </Moment>
                                                    </h6> : ""}

                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <Line
                                            type="line"
                                            data={chartData}
                                            options={{
                                                scales: {
                                                    yAxis: {
                                                        display: false
                                                    }
                                                },
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    }
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            

        </>
    );
};
export default HomeTopSection;


