import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import { SignInButton } from "./SignInButton";
import '../styles/LoginPage.css';

export const RouteGuard = ({ Component, ...props }) => {
    const authRequest = {
        ...loginRequest
    };
    const { instance, inProgress } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);

    const onLoad = async () => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount && currentAccount.idTokenClaims['roles']) {
            let intersection = props.roles
                .filter(role => currentAccount.idTokenClaims['roles'].includes(role));

            if (intersection.length > 0) {
                setIsAuthorized(true);
            }
        }
    }

    useEffect(() => {
        if (inProgress === InteractionStatus.None)
            onLoad();
    }, [inProgress, instance]);

    return (
        <>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}>
                {
                    isAuthorized
                        ?
                        <Route {...props} render={routeProps => <Component {...props} {...routeProps} />} />
                        :
                        <>
                            <div className="data-area-div">
                                <h3>You are unauthorized to view this content.</h3>
                            </div>
                            
                        </>
                }
            </MsalAuthenticationTemplate>
            <UnauthenticatedTemplate>
                {/*<div className="data-area-div">*/}
                {/*    <div id="login-area">*/}
                {/*        <div id="login-container">*/}
                {/*            <div id="login-header">*/}
                {/*                <h3 id="loginheader-text">Login to your account </h3>*/}
                {/*            </div>*/}
                {/*            <div id="login-button">*/}
                {/*                <SignInButton />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div id="image-area">*/}
                {/*        <div id="image-container">*/}
                {/*            <img id="login-image" src="./MacbookPro.svg" />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </UnauthenticatedTemplate>
        </>
    );
};